import { default as indexif8xBg25EOMeta } from "/opt/build/repo/pages/about/index.vue?macro=true";
import { default as indexwjElgkyyLmMeta } from "/opt/build/repo/pages/contact/index.vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as indexoqFO9D5awxMeta } from "/opt/build/repo/pages/samples/index.vue?macro=true";
import { default as pharmacy_45websiteZuKQKYmx7VMeta } from "/opt/build/repo/pages/samples/websites/pharmacy-website.vue?macro=true";
import { default as indexR3VTPuADQmMeta } from "/opt/build/repo/pages/semmverse/privacy-policy/index.vue?macro=true";
import { default as indexi5MzpDfjPNMeta } from "/opt/build/repo/pages/services/index.vue?macro=true";
import { default as indexhDj89dz2z7Meta } from "/opt/build/repo/pages/technologies/index.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    meta: indexif8xBg25EOMeta || {},
    component: () => import("/opt/build/repo/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "contact",
    path: "/contact",
    meta: indexwjElgkyyLmMeta || {},
    component: () => import("/opt/build/repo/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexlaFeZuYZkUMeta || {},
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "samples",
    path: "/samples",
    component: () => import("/opt/build/repo/pages/samples/index.vue").then(m => m.default || m)
  },
  {
    name: "samples-websites-pharmacy-website",
    path: "/samples/websites/pharmacy-website",
    meta: pharmacy_45websiteZuKQKYmx7VMeta || {},
    component: () => import("/opt/build/repo/pages/samples/websites/pharmacy-website.vue").then(m => m.default || m)
  },
  {
    name: "semmverse-privacy-policy",
    path: "/semmverse/privacy-policy",
    component: () => import("/opt/build/repo/pages/semmverse/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: "services",
    path: "/services",
    meta: indexi5MzpDfjPNMeta || {},
    component: () => import("/opt/build/repo/pages/services/index.vue").then(m => m.default || m)
  },
  {
    name: "technologies",
    path: "/technologies",
    meta: indexhDj89dz2z7Meta || {},
    component: () => import("/opt/build/repo/pages/technologies/index.vue").then(m => m.default || m)
  }
]